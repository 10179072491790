import React, {
  memo, useEffect, Suspense, lazy, useCallback,
} from 'react';
import {
  Route, Switch, useHistory,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import OrientationScreen from './components/Common/OrientationScreen';
import ErrorBoundary from './components/Common/ErrorBoundary';
import { hasActiveSession } from './service/Authentication';
import { initializeMixpanel } from './libs/mixpanel';
import FullScreenSpinner from './components/Spinner';
import './App.scss';
import emitter from './Models/Emitter';
import { CustomHistory } from './Models/History';
import { lazyComponentLoader } from './Utils/modal.util';
import { setUserAuthenticated } from './action/creator2';
import { resetStore } from './action/creator';
import { useDocumentTitleChange } from './CustomHooks/useDocumentTitleChange';
import { hideHelpCrunch, loadHelpcrunchScript } from './libs/helpcrunch';

const AuthenticatedRoute = lazy(() => lazyComponentLoader(() => import('./containers/AuthenticatedRoute')));
const Login = lazy(() => lazyComponentLoader(() => import('./containers/Login')));
const UnsubscribeProspect = lazy(() => lazyComponentLoader(() => import('./components/UnSubscribe')));
const Payments = lazy(() => lazyComponentLoader(() => import('./containers/Payments')));

// Call it once in your app. At the root of your app is the best place
toast.configure();

initializeMixpanel();

loadHelpcrunchScript();

function App() {
  const history = useHistory();
  useDocumentTitleChange();

  useEffect(() => () => {
    emitter.removeAllListeners();
  }, []);

  const dispatch = useDispatch();

  const onActiveSession = useCallback(
    () => {
      dispatch(setUserAuthenticated());
    },
    [dispatch],
  );

  const onInActiveSession = useCallback(
    () => {
      dispatch(resetStore());
    },
    [dispatch],
  );

  useEffect(() => {
    CustomHistory.setHistory(history);
    hideHelpCrunch();
    async function onLoad() {
      return hasActiveSession(onActiveSession, onInActiveSession);
    }
    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App-container">
      <ErrorBoundary>
        <Suspense fallback={<FullScreenSpinner text="Loading..." />}>
          <Switch>
            <Route exact path="/confirmCognitoUser" render={() => <Login userConfirmation />} />
            <Route exact path="/login" render={() => <Login />} />
            {/* <Route exact path="/signup" render={() => <Login isSignUpScreen />} /> */}
            <Route exact path="/signupComplete" render={() => <Login isSignupComplete />} />
            <Route exact path="/resetPassword" render={() => <Login showResetPasswordPage />} />
            <Route exact path="/unsubscribe" render={() => <UnsubscribeProspect />} />
            <Route exact path="/payments" component={Payments} />
            <Route path="/" component={AuthenticatedRoute} />
          </Switch>
        </Suspense>
        <OrientationScreen />
      </ErrorBoundary>
    </div>
  );
}

export default memo(App);
